import { Container } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { LoginForm } from 'modules/authentication';
import { AuthLogo } from 'modules/navigation';
import React from 'react';
import { auth } from 'styles';

const Login: React.FC = () => {
  return (
    <Container className={auth.loginFlex}>
      <AuthLogo />

      <LoginForm navigatePath="/" />

      <p>
        &copy; {new Date().getFullYear()}{' '}
        <FormattedMessage id="shared.copyright" />
      </p>
    </Container>
  );
};

export default Login;
